@import "../../assets/mixins/font_color_mixins";

.title {
    font-weight: 700;
    @media screen and (min-width: 1024px) {
        font-size: 24px;
    }
    @media screen and (max-width: 1023px) {
        font-size: 16px;
    }

    font-family: "Euphemia-UCAS-bold", sans-serif;
    color: $fabelle-primary-pink;
    margin: 16px 0px;
}

li {
    @include euphemia-400-20;
    color: black;
    margin-bottom: 24px;
    text-align: left;
}