@import './src/assets/mixins/font_color_mixins';



@mixin CustomRichBtn{
  padding: 9.5px 50.5px;
  border: none;
  position: relative;
  height: 48px;
  //margin-left: 26px;
  //margin-right: 26px;
  //font-family: 'Trade Gothic LT condensed 18', serif;
  //font-weight: 700;
  //font-size: 24px;
  //line-height: 29px;
  @include euphemia-700-20;
  //line-height: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: none;
  background-color: transparent;
  z-index: 2;
  &:focus{
    outline: none;
    border: none;
  }
  //&::before {
  //  content: '';
  //  width: 43px;
  //  height: 43px;
  //  position: absolute;
  //  left: -17px;
  //  top: 0;
  //  border-radius: 0 20px;
  //  transform: rotate(135deg);
  //}
  //&::after {
  //  content: '';
  //  width: 43px;
  //  height: 43px;
  //  position: absolute;
  //  right: -17px;
  //  top: 0;
  //  border-radius: 0 20px;
  //  transform: rotate(135deg);
  //}
}

@mixin CustomRichBtnMobile{
  padding: 4px 24px;
  //height: 32px;
  //@include euphemia-700-12;
  font-family: 'Euphemia-UCAS-bold', sans-serif;
  font-weight: 700;
  line-height: 0 !important;
  font-size: 16px;
  margin: 0 auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: none;
  background-color: transparent;
  z-index: 2;
  white-space: nowrap;
  &:focus{
    outline: none;
    border: none;
  }
  //&::before {
  //  width: 32px;
  //  height: 32px;
  //  border-radius: 0 16px;
  //}
  //&::after {
  //  width: 32px;
  //  height: 32px;
  //  border-radius: 0 16px;
  //}
}


.custom-button-primary{
  .custom-rich-btn{
    @include CustomRichBtn();
    height: 56px;
    &.dark{
      //background: #34231A;
      background-image: url("../../assets/images/dark-fill-btn-bg.png");
      color: #FFEA7D;
      //&::before,&::after{
      //  background: #34231A;
      //}
    }
    &.light{
      //background: #FFEA7D;
      background-image: url("../../assets/images/light-fill-btn-bg-redesigned.png");

      color: #34231A;
      //&::before,&::after{
      //  background: #FFEA7D;
      //}
    }
  }
}

@media screen and (max-width: 1023px) {
  .custom-button-primary{
    .custom-rich-btn{
      @include CustomRichBtnMobile();
      height: 40px;
      &.dark{
        //background: #34231A;
        color: #FFEA7D;
        //&::before,&::after{
        //  background: #34231A;
        //}
      }
      &.light{
        //background: #FFEA7D;
        color: #34231A;
        //&::before,&::after{
        //  background: #FFEA7D;
        //}
      }
    }
  }

}