// colors
$fabelle-primary-brown: #34231a;
$fabelle-primary-pink: #EB006B;
$fabelle-primary-gold: #BE924A;
$fabelle-primary-white: #ffffff;

$fabelle-secondary-gold-1: #ffeb95;
$fabelle-secondary-gold-2: #ffea7d;
$fabelle-secondary-gold-3: #ECC98F;

$fabelle-grey-shade: #BFBFBF;
$fabelle-primary-brown-2: #381E05;
$fabelle-primary-brown-3: #211102;
//$fabelle-primary-brown-4: #E5E5E5;
$fabelle-grey-text: #878787;
$fabelle-grey-text-2: #858585;
$fabelle-grey-text-3: #ADADAD;
$fabelle-primary-green: #97EC8F;
$fabelle-secondary-green: #00821A;
$fabelle-redesign-grey: #323232;

$fabelle-primary-red: #C62929;

// gold-gradiant
$fabelle-gold-gradiant: linear-gradient(90deg, #AD7819 0%, #DEA64A 20.77%, #FFEB95 35.13%, #EFBC68 48.97%, #FFD67A 60.77%, #BE8D3C 74.36%, #9E6C0C 100%);

//fonts

@mixin didot-400-72 {
  font-family: 'Didot-Italic', sans-serif;
  font-weight: 400;
  font-size: 72px;
  line-height: 120%;
  font-display: swap
}

@mixin didot-400-48 {
  font-family: 'Didot-Italic', sans-serif;
  font-weight: 400;
  font-size: 48px;
  line-height: 120%;
  font-display: swap
}

@mixin didot-400-40 {
  font-family: 'Didot-Italic', sans-serif;
  font-weight: 400;
  font-size: 40px;
  line-height: 120%;
  font-display: swap
}

@mixin didot-400-32 {
  font-family: 'Didot-Italic', sans-serif;
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  font-display: swap
}

@mixin didot-400-24 {
  font-family: 'Didot-Italic', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  font-display: swap
}

@mixin didot-400-20 {
  font-family: 'Didot-Italic', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  font-display: swap
}

@mixin blair-300-24 {
  font-family: 'Blair ITC TT', sans-serif;
  font-weight: 300;
  font-size: 24px;
  line-height: 120%;
  font-display: swap
}

@mixin blair-700-24 {
  font-family: 'Blair ITC TT', serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  font-display: swap
}

@mixin blair-700-20 {
  font-family: 'Blair ITC TT', serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  font-display: swap
}

// trade con
@mixin trade-con-500-12 {
  font-family: 'Trade Gothic LT condensed 18', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  font-display: swap
}

@mixin trade-con-500-14 {
  font-family: 'Trade Gothic LT condensed 18', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  font-display: swap
}

@mixin trade-con-500-16 {
  font-family: 'Trade Gothic LT condensed 18', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  font-display: swap
}

@mixin trade-con-500-20 {
  font-family: 'Trade Gothic LT condensed 18', sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  font-display: swap
}

@mixin trade-con-500-24 {
  font-family: 'Trade Gothic LT condensed 18', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  font-display: swap
}

// trade
@mixin trade-700-16 {
  font-family: 'Trade Gothic LT', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  font-display: swap
}

@mixin trade-700-20 {
  font-family: 'Trade Gothic LT', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  font-display: swap
}

@mixin trade-700-24 {
  font-family: 'Trade Gothic LT', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  font-display: swap
}

@mixin trade-500-16 {
  font-family: 'TradeGothic LT Bold', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  font-display: swap
}

@mixin trade-500-20 {
  font-family: 'TradeGothic LT Bold', sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  font-display: swap
}

@mixin trade-500-24 {
  font-family: 'TradeGothic LT Bold', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  font-display: swap
}

@mixin trade-con-700-16 {
  font-family: 'Trade Gothic LT condensed 18', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  font-display: swap
}

@mixin trade-con-700-14 {
  font-family: 'Trade Gothic LT condensed 18', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  font-display: swap
}

@mixin trade-con-700-12 {
  font-family: 'Trade Gothic LT condensed 18', sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 120%;
  font-display: swap
}

@mixin trade-con-700-20 {
  font-family: 'Trade Gothic LT condensed 18', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  font-display: swap
}

@mixin trade-con-700-24 {
  font-family: 'Trade Gothic LT condensed 18', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  font-display: swap
}

@mixin trade-con-700-32 {
  font-family: 'Trade Gothic LT condensed 18', sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  font-display: swap
}

@mixin trade-con-700-40 {
  font-family: 'Trade Gothic LT condensed 18', sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  font-display: swap
}

// euphemia regular
@mixin euphemia-400-10 {
  font-family: 'Euphemia-UCAS-regular', sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  font-display: swap
}

@mixin euphemia-400-12 {
  font-family: 'Euphemia-UCAS-regular', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  font-display: swap
}

@mixin euphemia-400-14 {
  font-family: 'Euphemia-UCAS-regular', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  font-display: swap
}

@mixin euphemia-400-16 {
  font-family: 'Euphemia-UCAS-regular', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  font-display: swap
}

@mixin euphemia-400-18 {
  font-family: 'Euphemia-UCAS-regular', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  font-display: swap
}

@mixin euphemia-400-19 {
  font-family: 'Euphemia-UCAS-regular', sans-serif;
  font-weight: 400;
  font-size: 19px;
  line-height: 120%;
  font-display: swap
}

@mixin euphemia-400-20 {
  font-family: 'Euphemia-UCAS-regular', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  font-display: swap
}

@mixin euphemia-400-24 {
  font-family: 'Euphemia-UCAS-regular', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  font-display: swap
}

@mixin euphemia-400-32 {
  font-family: 'Euphemia-UCAS-regular', sans-serif;
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  font-display: swap
}

@mixin euphemia-400-40 {
  font-family: 'Euphemia-UCAS-regular', sans-serif;
  font-weight: 400;
  font-size: 40px;
  line-height: 120%;
  font-display: swap
}

@mixin euphemia-400-48 {
  font-family: 'Euphemia-UCAS-regular', sans-serif;
  font-weight: 400;
  font-size: 48px;
  line-height: 120%;
  font-display: swap
}

@mixin euphemia-400-72 {
  font-family: 'Euphemia-UCAS-regular', sans-serif;
  font-weight: 400;
  font-size: 72px;
  line-height: 120%;
  font-display: swap
}

// euphemia bold
@mixin euphemia-700-12 {
  font-family: 'Euphemia-UCAS-bold', sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 120%;
  font-display: swap
}

@mixin euphemia-700-14 {
  font-family: 'Euphemia-UCAS-bold', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  font-display: swap
}

@mixin euphemia-700-16 {
  font-family: 'Euphemia-UCAS-bold', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  font-display: swap
}

@mixin euphemia-700-18 {
  font-family: 'Euphemia-UCAS-bold', sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  font-display: swap
}

@mixin euphemia-700-20 {
  font-family: 'Euphemia-UCAS-bold', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  font-display: swap
}

@mixin euphemia-700-24 {
  font-family: 'Euphemia-UCAS-bold', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  font-display: swap
}

@mixin euphemia-700-32 {
  font-family: 'Euphemia-UCAS-bold', sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  font-display: swap
}

@mixin euphemia-700-36 {
  font-family: 'Euphemia-UCAS-bold', sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  font-display: swap
}

@mixin euphemia-700-40 {
  font-family: 'Euphemia-UCAS-bold', sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  font-display: swap
}

@mixin euphemia-700-48 {
  font-family: 'Euphemia-UCAS-bold', sans-serif;
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  font-display: swap
}

@mixin euphemia-700-72 {
  font-family: 'Euphemia-UCAS-bold', sans-serif;
  font-weight: 700;
  font-size: 72px;
  line-height: 120%;
  font-display: swap
}

@mixin euphemia-700-10 {
  font-family: 'Euphemia-UCAS-bold', sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 120%;
  font-display: swap
}

// euphemia italic

// rubix
@mixin rubik-400-14 {
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  font-display: swap
}