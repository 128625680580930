.custom-toast-component{
  gap: 10px;

  .message{
    font-family: "Trade Gothic LT condensed 18",sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #34231A;
    margin: 0;
  }
}
