@import '../../assets/common/common_style';

.main-page-layout {
  .golden-border {
    background-color: $fabelle-secondary-gold-1;
    height: 1px;
    width: 100%;
  }

  .credit-footer {
    padding: 30px 0;
    background-color: $fabelle-primary-brown-2;
  }

  .contact-content-card {
    background-color: $fabelle-primary-brown;
    border-radius: 16px;
    box-shadow: 0 1px 3px 5px $fabelle-secondary-gold-1;
    padding: 16px;
    position: fixed;
    bottom: 130px;
    right: 24px;
z-index: 100000;
  }

  .contact-floating-button {
    background-color: $fabelle-primary-brown;
    border-radius: 100%;
    box-shadow: 0 1px 3px 5px $fabelle-secondary-gold-1;
    padding: 16px;
    position: fixed;
    bottom: 56px;
    right: 24px;
    z-index:100000;
  }

  .highlight {
    box-shadow: 0 1px 3px 5px $fabelle-secondary-gold-1;
  }

  .normal {
    box-shadow: 0 0px 3px 2px $fabelle-secondary-gold-1;
  }

}