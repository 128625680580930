@import "../../assets/mixins/font_color_mixins";

.navigation-bar-component-wrapper {
  position: relative;
  //padding-bottom: 7rem;

  .offer-ribbon {
    //background: linear-gradient(90deg, #AD781910 0%, #DEA64A 20.77%, #FFEB95 35.13%, #EFBC68 48.97%, #FFD67A 60.77%, #BE8D3C 74.36%, #9E6C0C10 100%);
    background-color: $fabelle-primary-brown;

    a {
      @include euphemia-700-12;
      color: $fabelle-primary-white;
      text-decoration: underline;
      //color: black;
    }
  }

  .sub-category-list {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;

    &.open {
      max-height: 1000px;
      transition: max-height 0.25s ease-in;
    }
  }

  .fixed-custom-navbar {
    background-color: $fabelle-primary-brown-2;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000000;
    transition: 0.5s;
    &.scrolled-down{
      transform:translateY(-100%); transition: all 0.3s ease-in-out;
    }
    &.scrolled-up{
      transform:translateY(0); transition: all 0.3s ease-in-out;
    }

    .navigation-bar-component {
      padding: 24px 80px;
      gap: 40px;
      max-width: 1444px;
      margin: auto;
      position: relative;

      .mobile-logo-img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }

      .navbar-menu-list {
        .cursor-pointer {
          .left-arrow, .right-arrow {
            width: 11px;
            height: 15px;
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s, opacity 0.5s linear;
          }

          &.active {
            .left-arrow, .right-arrow {
              visibility: visible;
              opacity: 1;
            }
          }

        }

        .mega-menu-item {
          .mega-menu {
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s, opacity 0.5s ease-in-out;
            position: absolute;
            width: 100%;
            left: 0;
            z-index: 1;
            background-color: $fabelle-primary-brown-2;
            //top: 100%;
          }

          &:hover .mega-menu {
            visibility: visible;
            opacity: 1;

          }

          //& .active{
          //  border-bottom: 2px solid $fabelle-primary-gold;
          //}

        }

      }
    }
  }

  .cart-badge {
    width: 14px;
    height: 14px;
    background-color: #eb006b;
    color: $fabelle-secondary-gold-1;
    @include trade-con-500-12;
    padding: 2px;
    text-align: center;
    border-radius: 100%;
    position: absolute;
    right: -4px;
    top: -4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }

  

  .vertical-divider-line {
    background: linear-gradient(0deg, #AD781910 0%, #DEA64A 20.77%, #FFEB95 35.13%, #EFBC68 48.97%, #FFD67A 60.77%, #BE8D3C 74.36%, #9E6C0C10 100%);
    height: 85%;
    width: 3px;
    margin: 0;
  }

  .navigation-bottom-line {
    background: linear-gradient(90deg, #AD781910 0%, #DEA64A 20.77%, #FFEB95 35.13%, #EFBC68 48.97%, #FFD67A 60.77%, #BE8D3C 74.36%, #9E6C0C10 100%);
    height: 3px;
    width: 100%;
  }

  .show-drop-content,
  .hide-drop-content {
    position: absolute;
    top: 100px;
    width: 100%;
    background: #381C02;
    z-index: 100;
  }

  .show-drop-content {
    display: block;
    transition: ease-in-out;
    animation: fade-in;
  }

  .hide-drop-content {
    display: none;
    transition: ease-in-out;
    animation: fade-out;
  }

  .navigation-card-links {
    div {
      position: relative;
    }

    p {
      cursor: pointer;
    }

    hr {
      position: absolute;
      height: 2px;
      width: 100%;
      background: linear-gradient(90deg, #AD781905 0%, #DEA64A70 20.77%, #FFEB95 35.13%, #EFBC68 48.97%, #FFD67A 60.77%, #BE8D3C70 74.36%, #9E6C0C05 100%);
      margin: 0;
      bottom: -4px;
      animation-name: grow;
      animation-duration: 1s;
      transition: ease-in-out 1s;
    }

    @keyframes grow {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
  }

  .navigation-card-image {
    //width: 486px;
    //object-fit: contain;
    width: inherit;
  }

  .floating-search-box {
    position: absolute;
    bottom: -500%;
    left: 50%;
    background-color: #34231a;
    padding: 20px;
    border-radius: 8px;
    transform: translateX(-50%);

    .input-wrapper {
      background-color: white;
      border-radius: 4px;
      padding: 8px 17px;
    }

    .input-box {
      border: no;
    }
  }

  .hr-wrapper {
    background: #381E05;
  }

  .dropdown-content {
    position: absolute;
    top: -5px;
    padding: 20px;
    width: 200px;
    left: -50px;
    z-index: 1000;

    p {
      margin-bottom: 20px !important;
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  //  login modal
  .login-modal {

    @media screen and (max-width: 1024px) {

      .modal-backdrop {
        z-index: 100000000;
      }
      .modal {
        left: 0;
        width: 100%;
        height: fit-content;
        bottom: 0;
        top: auto;
        border-radius: 12px 12px 0px 0px;
      }
      .modal-dialog {
        margin: 0;
        z-index: 1000000;
        height: fit-content;
        bottom: 0;
        top: auto;
      }

      .modal-content {
        background: transparent;
        border-radius: 15px;
        height: fit-content;
      }
    }
    @media screen and (min-width: 1024px) {

      .modal {
        left: 50%;
        transform: translateX(-50%);
        width: 75vw;
      }
      .modal-dialog {
        margin: 0;
      }

      .modal-content {
        background: #381E05;
        border-radius: 15px;
      }
    }

    .modal-body {
      padding: 0;
    }

  }

  // search modal - mobile
  .search-modal-mobile {
    .modal {
      z-index: 100000000;
      height: 100vh;
    }

    .modal-content {
      height: 100vh;
    }

    .search-modal-content {
      height: 100vh;
      overflow-y: auto;
      position: relative;

      .loading-wrapper {
        position: absolute;
        top: 35%;
        transform: translate(-50%, -50%);
        left: 50%;
      }
    }

    .search-modal-header {
      background-color: $fabelle-primary-brown-2;
    }

    .product-column {
      margin-bottom: 40px;

      .product-card-component {
        margin: 0 auto;
      }
    }

    .modal-body {
      padding: 0 !important;
    }

    @media screen and (max-width: 1024px) {
      .modal {
        left: 0;
        width: 100%;
      }
      .modal-dialog {
        margin: 0;
        height: 100%;
      }

      .modal-content {
        background: white;
        border-radius: 15px;
        height: 100%;
      }
    }
    @media screen and (min-width: 1024px) {

      .modal {
        left: 50%;
        transform: translateX(-50%);
        width: 75vw;
      }
      .modal-dialog {
        margin: 0;
      }

      .modal-content {
        background: white;
        border-radius: 15px;
      }
    }

    .modal-body {
      padding: 0;
    }
  }
}

@media screen and (max-width: 1024px) {
  .navigation-bar-component-wrapper {
    //padding-bottom: 5rem;

    .navigation-bar-component {
      padding: 24px 26px !important;
      justify-content: space-between !important;
      gap: 0 !important;
    }
  }
}
