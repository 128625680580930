@import "../../assets/mixins/font_color_mixins";

.sidebar-component{
  .modal {
    width: 100%;
    height: 100%;
    z-index: 100000;
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    //background-color: #000;
    //backdrop-filter: blur(2px);
    opacity: 1;
  }

  .modal-dialog {
    margin: 0;
    //@include PageWidth;
    height: 100vh;

    .modal-content {
      border-radius: 16px 16px 0 0;
      height: 100vh;
      //height: 100vh;
      //max-height: 100vh;
      //background: url("../../assets/images/rectangle-bg.png") #FFFFFF no-repeat top;
      //background-size: contain;

      .border-hidden {
        border: none;
      }

      .modal-body {
        //max-height: 70vh;
        background-color: black;
        height: 100vh;
        overflow: auto;
        padding: 0 16px 16px 16px;
      }

    }
  }

  .modal-bottom {
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    //top: auto;
    //min-height: 0;
    //display: block;
    //width: 100%;
    //max-width: 100%;
    //height: auto;
    //bottom: 0;
    //position: absolute;
    //right: 0;
  }

  .modal-component-header {
    position: relative;
    padding: 20px 0;

  }
}