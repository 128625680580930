@import './src/assets/mixins/font_color_mixins';

@mixin SectionSubTitle{
  font-family: "Didot-Italic",sans-serif;
  font-size: 48px;
  line-height: 58px;
  color: #FFFFFF;
}

.heading-wrapper-section{
  gap: 24px;
  .sub-title{
    @include euphemia-400-40;
    text-align: center;
    color: black;
  }
}

@media screen and (max-width: 1023px) {
  .heading-wrapper-section{
    gap: 12px;
    img{
      width: 24px;
    }
    .sub-title{
      @include euphemia-400-20;
    }
  }
}
