@import '../../assets/mixins/font_color_mixins';
@import '../../assets/common/common_style';
@import '../../assets/mixins/component_style_mixins';

.desktop-login-component {
  position: relative;
  background: white;
  //border-radius: 15px;

  .mobile-top-bar {
    padding: 24px 24px 0 24px;
    justify-content: end;
  }

  .button-section {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background: #34231A;
    padding: 12px 39px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;

    .custom-btn-section {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }

  // mobile
  @media screen and (max-width: 1024px) {
    .login-image {
      background-image: url("../../assets/webp_images/Loginpage/mobile.webp");
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
      height: 303px;
    }
    .content-area {
      padding: 8px 24px 24px;
      position: relative;
      background-image: none;
      //background-position: center;
      //background-size: cover;
      //background-repeat: no-repeat;

      .inner-content {
        //min-height: 400px;
        display: flex;
        flex-flow: column;
        justify-content: center;
      }

    }
  }
  //desktop
  @media screen and (min-width: 1024px) {
    .login-image {
      background-image: url("../../assets/webp_images/Loginpage/Desktop.webp");
      background-position: left;
      background-size: cover;
      background-repeat: no-repeat;

    }
    .content-area {
      padding: 28px;
      background-image: url("../../assets/images/login_page_bg_desktop.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: right;

      .inner-content {
        min-height: 400px;
        display: flex;
        flex-flow: column;
        justify-content: center;
      }

    }
  }

  .navigation-box {
    position: relative;
    padding: 11px 10px;

    .ico-1 {
      position: absolute;
      top: 0;
      left: 0;
    }

    .ico-2 {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

@media screen and (max-width: 1024px) {
  .desktop-login-component {
    height: fit-content;
    background-image: url("../../assets/images/login_page_bg_mobilepng.png");
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 40px;
  }
}


