@import '../../assets/common/common_style';

@import '../../assets/mixins/_component_style_mixins.scss';

.input-component {
  max-width: 80%;
  .input-box {
    padding: 14px !important;
    font-style: normal;
    color: #000;
    padding: 14px 20px !important;
    font-family: 'Euphemia-UCAS-bold', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;
    font-display: swap;

    &:focus{
      color: #000;
      font-family: 'Euphemia-UCAS-bold', sans-serif;
      font-weight: 700;
      font-size: 14px;
      line-height: 120%;
      font-display: swap;
    }
  }

  @include input-box;

  .golden-bg {
    background-color: $fabelle-primary-gold;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
  }

  .white-bg {
    background-color: white;
  }

  @media screen and (max-width: $isTabletOrMobile-max) {
    max-width: 100%;    
  }
}