@import './font_color_mixins';
@import '../common/common_style';

@mixin input-box {

  @media screen and (max-width: $isTabletOrMobile-max) {

    input,
    input:active,
    input:focus {
      background-color: white;
      color: #C6C6C6;
      border: none;
      outline: none;
      @include trade-700-16;
      font-style: italic;
      padding: 14px 20px !important;
    }
  }

  @media screen and (min-width: $isDesktopOrLaptop-min) {

    input,
    input:active,
    input:focus {
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      background-color: white;
      color: $fabelle-grey-text;
      border: none;
      outline: none;
      @include trade-500-16;

    }
  }

}

@mixin delivery-modal-cart-component {
  .modal-dialog {

    .modal-content {
      border-radius: 16px 16px 0 0;
      height: 50vh;
      padding: 16px;
      //height: 100vh;
      //max-height: 100vh;
      //background: url("../../assets/images/rectangle-bg.png") #FFFFFF no-repeat top;
      //background-size: contain;

      .border-hidden {
        border: none;
      }

      .modal-body {
        //max-height: 70vh;
        background-color: white;
        height: 50vh;
        overflow: auto;
        padding: 0;
      }

    }
  }
  .delivery-modal-section {





    .address-input {
      border: 1px solid $fabelle-secondary-gold-1;
      border-radius: 12px;
      z-index: 0;

      select,
      select:focus,
      select:active,
      input,
      input:focus,
      input:active {
        z-index: 0 !important;
        border: none;
        outline: none;
        @include trade-con-500-16;
        width: 100%;
        max-width: 560px;
        color: $fabelle-primary-brown;
        background: transparent;
      }

    }
  }
}
.redesigned-input-style-gold {
  border: 1px solid $fabelle-primary-gold !important;
  border-radius: 12px;
  z-index: 0;

  select,
  select:focus,
  select:active,
  input,
  input:focus,
  input:active {
    z-index: 0 !important;
    border: none;
    outline: none;
    @include euphemia-400-16;
    width: 100%;
    max-width: 560px;
    color: $fabelle-primary-brown;
    background: transparent;
  }
}

@mixin fabelle-brown-button-fill {
  background: #34231A;
  //@include trade-con-700-24;
  text-align: center;
  padding: 9px 0;
  color: $fabelle-secondary-gold-1;
}

@mixin fabelle-gold-button-fill {
  background: #FFEA7D;
  border: 2px solid #FFEA7D;
  //@include trade-con-700-24;
  text-align: center;
  padding: 9px 0;
  color: $fabelle-primary-brown;
}

@mixin fabelle-gold-button-outline {
  border: 2px solid #FFEA7D;
  background-color: transparent;
  //@include trade-con-700-24;
  text-align: center;
  padding: 9px 0;
  color: $fabelle-secondary-gold-1;
}
.redesigned-input-style {
  border: 1px solid $fabelle-primary-brown !important;
  border-radius: 12px;
  z-index: 0;

  select,
  select:focus,
  select:active,
  input,
  input:focus,
  input:active {
    z-index: 0 !important;
    border: none;
    outline: none;
    @include euphemia-400-16;
    width: 100%;
    max-width: 560px;
    color: $fabelle-primary-brown;
    background: transparent;
  }
}