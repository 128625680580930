@import '../../assets/mixins/font_color_mixins';
@import '../../assets/common/common_style';
@import '../../assets/mixins/component_style_mixins';

.verify-otp-component {
  max-width: 362px;
  @media screen and (max-width: $isTabletOrMobile-max) {

    .address-input {
      border: 1px solid $fabelle-secondary-gold-1;
      z-index: 0;

      select,
      select:focus,
      select:active,
      input,
      input:focus,
      input:active {
        z-index: 0 !important;
        border: none !important;
        outline: none !important;
        @include trade-con-500-16;
        width: 100%;
        max-width: 560px;
        color: $fabelle-primary-brown;
        background:transparent;
      }

    }
  }
  @media screen and (min-width: $isDesktopOrLaptop-min) {

    .address-input {
      border: 2px solid $fabelle-primary-brown;
      z-index: 0;

      select,
      select:focus,
      select:active,
      input,
      input:focus,
      input:active {
        z-index: 0 !important;
        border: none !important;
        outline: none !important;
        @include trade-con-500-16;
        width: 100%;
        max-width: 560px;
        color: $fabelle-primary-brown;
        background:transparent;
      }
    }
  }

  .address-input {
    position: relative;
    padding: 10px;
    // border changed accor to size >
    background: rgba(255, 234, 125, 0.25) !important;
    border: 1px solid #34231A;
    border-radius: 5px;
    //.address-input-wrap{
    //  background-color: $fabelle-primary-brown;
    //  padding: 18px 10px;
    //}

    .label-value {
      position: absolute;
      top: -12px;
      left: 24px;
      text-transform: capitalize;
      color: $fabelle-primary-gold;
      padding: 0 2px;
    }

    .hidden {
      display: none;
      animation: fade-out;
      animation-duration: 1s;
    }

    .show {
      animation: fade-in;
      animation-duration: .5s;
    }

    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes fade-out {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }

  .otp-input-container{
    .otp-field{
      display: flex;
      justify-content: space-between;
      .otp-field__input{
        display: flex;
        align-items: center;
        padding: 10px;
        background: rgba(255, 234, 125, 0.25) !important;
        border: 1px solid #34231A;
        border-radius: 5px;
        width: 68px;
        height: 68px;
        justify-content: center;
        text-align: center;
      }
    }
  }
}
