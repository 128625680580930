@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //@media screen and (max-width: 1024px) {
  //  padding-top: 101px!important;
  //}
}

$image_path: "https://foods-fabelle-app.s3.amazonaws.com/FrontE/font/";

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html,body{
  scroll-behavior: smooth;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

input:-webkit-autofill {
  background-color: transparent;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */

button:active,
button:focus,
button{
  outline:none !important;
}


@font-face {
  font-family: 'Didot-Italic';
  src: local('Didot-Italic'),url($image_path +'Didot-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Blair ITC TT';
  font-weight: 300;
  src: local('Blair ITC TT'),url($image_path + 'blairitc-tt-light.ttf') format('truetype');
}
@font-face {
  font-family: 'Blair ITC TT';
  font-weight: 700;
  src: local('Blair ITC TT'),url($image_path + 'blairitc-tt-bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Blair ITC TT';
  src: local('Blair ITC TT'),url($image_path + 'blair-itc-medium-6946.ttf') format('truetype');
}
@font-face {
  font-family: 'TradeGothic LT Bold';
    src: local('Trade Gothic LT'),url($image_path + 'Trade-Gothic-LT.ttf') format('truetype');
}
@font-face {
  font-family: 'Trade Gothic LT condensed 18';
  src: local('Trade Gothic LT'),url($image_path + 'Trade-Gothic-LT-Condensed-No.18.ttf') format('truetype');
}

@font-face {
  font-family: "BlairITC-TT-Bold";
  src: url($image_path +"blairitc-tt-bold.ttf") format("truetype");
}
@font-face {
  font-family: "Didot-Italic";
  src: url($image_path +"/Didot-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Trade-Gothic-LT-Light";
  src: url($image_path +"/Trade-Gothic-LT-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Trade-Gothic-LT-Bold";
  src: url($image_path +"/Trade-Gothic-LT-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "blairitc-tt-light";
  src: url($image_path +"blairitc-tt-light.ttf") format("truetype");
}
@font-face{
  font-family:"Euphemia-UCAS-regular";
  src: url($image_path +"Euphemia-UCAS-Regular-2.6.6.ttf");
}
@font-face{
  font-family:"Euphemia-UCAS-bold";
  src: url($image_path +"Euphemia-UCAS-Bold-2.6.6.ttf");
}
@font-face{
  font-family:"Euphemia-UCAS-italic";
  src: url($image_path +"Euphemia-UCAS-Italic-2.6.6.ttf");
}


select::-ms-expand {
  display: none;

}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.gap-3{
  gap: 16px;
}


//styles for Custom Toast
#ct-container{
  .ct-row{
    .ct-group{
      .ct-toast{
        background: linear-gradient(90deg, #AD7819 0%, #DEA64A 20.77%, #FFEB95 35.13%, #EFBC68 48.97%, #FFD67A 60.77%, #BE8D3C 74.36%, #9E6C0C 100%);
        box-shadow: 0px 40px 54px rgba(0, 0, 0, 0.34);
        border-radius: 8px;
        border: none !important;
        padding: 2px;
        svg{
          display: none;
        }
        .ct-text-group{
          margin: 0;
          border-radius: 8px;
          background: #FFEA7D;
          padding: 12px 31.5px;
          .ct-text {
            font-size: initial;
          }
        }
      }
    }
  }
}


a {
  cursor: pointer;
}

a.disabled {
  cursor: not-allowed;
}

.login-modal {

  @media screen and (max-width: 1024px) {

    .modal-backdrop {
      z-index: 100000000;
    }
    .modal {
      left: 0;
      width: 100%;
      height: fit-content;
      bottom: 0;
      top: auto;
      border-radius: 12px 12px 0px 0px;
    }
    .modal-dialog {
      margin: 0;
      z-index: 1000000;
      height: fit-content;
      bottom: 0;
      top: auto;
    }

    .modal-content {
      background: transparent;
      border-radius: 15px;
      height: fit-content;
    }
  }
  @media screen and (min-width: 1024px) {

    .modal {
      left: 50%;
      transform: translateX(-50%);
      width: 75vw;
    }
    .modal-dialog {
      margin: 0;
    }

    .modal-content {
      background: #381E05;
      border-radius: 15px;
    }
  }

  .modal-body {
    padding: 0;
  }

}

.input-field-item {
  position: relative;
  //margin-bottom: 20px;
  input, select {
    border: 1px solid #34231a;
    padding: 18px 10px;
    border-radius: 12px;
    width: 100%;
    color: #34231a;
    font-family: Euphemia-UCAS-regular, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;

    &:focus {
      outline: none;
    }
  }

  label {
    position: absolute;
    top: 0;
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    left: 1.5rem;
    pointer-events: none;
    transform-origin: 0 0;
    transition: all .2s ease-out;
    color: #BE924A;
    margin-bottom: 0;
    transform: translateY(0.3rem) translateY(-1rem);
    background: #FFFFFF;
    font-weight: 400;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("./assets/icons/dropdown-arrow-with-white-bg.svg");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 10px);
    background-position-y: 50%;
  }

  .number-prefix {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-family: Trade Gothic LT condensed\ 18, sans-serif;
    font-weight: 700;
    line-height: 120%;
  }
}


.cart-confetti-section{
  background: #D9FFD3;
  padding: 8px 20px 12px 20px;
  gap: 10px;

  img {
    width: 30px;
    height: 30px;
  }

  p{
    color:#00821A;
  }
}