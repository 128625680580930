@import "../../assets/mixins/font_color_mixins";

$isDesktopOrLaptop-min: 1025px;
$isTabletOrMobile-max: 1024px;

.fabelle-primary-brown {
  color: $fabelle-primary-brown;
}

.fabelle-primary-pink {
  color: $fabelle-primary-pink;
}

.fabelle-primary-gold {
  color: $fabelle-primary-gold;
}

.fabelle-primary-white {
  color: $fabelle-primary-white !important;
}

.fabelle-secondary-gold-1 {
  color: $fabelle-secondary-gold-1;
}

.fabelle-secondary-gold-2 {
  color: $fabelle-secondary-gold-2;
}

.fabelle-secondary-gold-3 {
  color: $fabelle-secondary-gold-3;
}

.fabelle-grey-shade {
  color: $fabelle-grey-shade;
}

.fabelle-grey-text-2 {
  color: $fabelle-grey-text-2;
}

.fabelle-grey-text-3 {
  color: $fabelle-grey-text-3;
}

.fabelle-gold-gradiant {
  color: $fabelle-gold-gradiant;
}

.fabelle-primary-brown-2 {
  color: $fabelle-primary-brown-2;
}

.fabelle-primary-green {
  color: $fabelle-primary-green;
}

.fabelle-secondary-green {
  color: #008F1D;
}

.fabelle-redesign-grey {
  color: $fabelle-redesign-grey;
}

.fabelle-primary-red {
  color: $fabelle-primary-red;
}

// fonts
.didot-400-72 {
  @include didot-400-72;
}

.didot-400-48 {
  @include didot-400-48;
}

.didot-400-40 {
  @include didot-400-40;
}

.didot-400-32 {
  @include didot-400-32;
}

.didot-400-24 {
  @include didot-400-24;
}

.didot-400-20 {
  @include didot-400-20;
}

.blair-300-24 {
  @include blair-300-24;
}

.blair-700-24 {
  @include blair-700-24;
}

.trade-500-16 {
  @include trade-500-16;
}

.trade-500-20 {
  @include trade-500-20;
}

.trade-500-24 {
  @include trade-500-24;
}

.trade-con-500-12 {
  @include trade-con-500-12;
}

.trade-con-500-14 {
  @include trade-con-500-14;
}

.trade-con-500-16 {
  @include trade-con-500-16;
}

.trade-con-500-20 {
  @include trade-con-500-20;
}

.trade-con-500-24 {
  @include trade-con-500-24;
}

.trade-700-16 {
  @include trade-700-16;
}

.trade-700-20 {
  @include trade-700-20;
}

.trade-700-24 {
  @include trade-700-24;
}

.trade-con-700-12 {
  @include trade-con-700-12;
}

.trade-con-700-14 {
  @include trade-con-700-14;
}

.trade-con-700-16 {
  @include trade-con-700-16;
}

.trade-con-700-20 {
  @include trade-con-700-20;
}

.trade-con-700-24 {
  @include trade-con-700-24;
}

.trade-con-700-32 {
  @include trade-con-700-32;
}

.trade-con-700-40 {
  @include trade-con-700-40;
}

// euphemia font classes

.euphemia-400-10 {
  @include euphemia-400-10;
}

.euphemia-400-12 {
  @include euphemia-400-12;
}

.euphemia-400-14 {
  @include euphemia-400-14;
}

.euphemia-400-16 {
  @include euphemia-400-16;
}

.euphemia-400-18 {
  @include euphemia-400-18;
}

.euphemia-400-19 {
  @include euphemia-400-19;
}

.euphemia-400-20 {
  @include euphemia-400-20;
}

.euphemia-400-24 {
  @include euphemia-400-24;
}

.euphemia-400-32 {
  @include euphemia-400-32;
}

.euphemia-400-40 {
  @include euphemia-400-40;
}

.euphemia-400-48 {
  @include euphemia-400-48;
}

.euphemia-400-72 {
  @include euphemia-400-72;
}

.euphemia-700-12 {
  @include euphemia-700-12;
}

.euphemia-700-14 {
  @include euphemia-700-14;
}

.euphemia-700-16 {
  @include euphemia-700-16;
}

.euphemia-700-18 {
  @include euphemia-700-18;
}

.euphemia-700-20 {
  @include euphemia-700-20;
}

.euphemia-700-24 {
  @include euphemia-700-24;
}

.euphemia-700-32 {
  @include euphemia-700-32;
}

.euphemia-700-36 {
  @include euphemia-700-36;
}

.euphemia-700-40 {
  @include euphemia-700-40;
}

.euphemia-700-48 {
  @include euphemia-700-48;
}

.euphemia-700-72 {
  @include euphemia-700-72;
}
.euphemia-700-10 {
  @include euphemia-700-10;
}

.rubik-400-14 {
  @include rubik-400-14;
}

@media screen and (max-width: $isTabletOrMobile-max) {
  .container-side-padding {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (min-width: $isDesktopOrLaptop-min) {
  .container-side-padding {
    padding-left: 104px;
    padding-right: 104px;
  }
}

.loading-asset {
  animation-iteration-count: infinite;
  animation-name: rotate;
  animation-timing-function: linear;
  animation-duration: 5s;
  width: 48px;
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }
  25% {
    transform: rotateZ(90deg);
  }
  50% {
    transform: rotateZ(180deg);
  }
  75% {
    transform: rotateZ(270deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.icon-8 {
  width: 8px !important;
  height: 8px !important;
  cursor: pointer;
}

.icon-40 {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.icon-24 {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.icon-20 {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.icon-18 {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.icon-16 {
  width: 16px !important;
  height: 16px !important;
  cursor: pointer;
}

.icon-32 {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer
}

.fabelle-brown-background {
  background: #211102;
}

.fabelle-white-background {
  background: #FFFFFF;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.margin-bottom-32 {
  margin-bottom: 32px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {

  .mobile-bottom-button-section {
    background-image: url("../../assets/images/detail-page-mobile-bottom-section.png");
    background-size: 100% 100%;
    min-height: 64px;
    width: 100%;
    position: fixed;
    bottom: 0;
    background-position-x: center;
    padding: 12px 24px 0;
    //border-top-left-radius: 50px;
    //border-top-right-radius: 50px;
    //background: #34231A;
    border: none;
    z-index: 10000000 !important;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {

  .mobile-bottom-button-section {
    //padding: 12px 48px 0;
    background-image: url("../../assets/images/detail-page-mobile-bottom-section.png");
    background-size: 100% 100%;
    min-height: 64px;
    width: 100%;
    position: fixed;
    bottom: 0;
    background-position-x: center;
    padding: 12px 24px 0;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    background: white;
    border: none;
    z-index: 10000;

  }
}

.settle-at-bottom {
  //width: 100%;
  position: fixed;
  bottom: 0;
}

.absolute-horizontal-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.border-radius-4 {
  border-radius: 4px !important;
}

.border-radius-8 {
  border-radius: 8px;
}

.border-radius-12 {
  border-radius: 12px;
}

.border-radius-16 {
  border-radius: 16px;
}

.border-less-input,
.border-less-input:active,
.border-less-input:focus {
  border: none;
  outline: none;
}

.fabelle-brown-background-2 {
  background-color: #34231A;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.gold-frame-button-redesign {
  background-image: url("../../assets/images/gold-frame-button-redesign.png");
  text-align: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  outline: none;
}

.gold-frame-button-redesign-40h {
  background-image: url("../../assets/images/gold-frame-button-redesign-40h.png");
  text-align: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  outline: none;
}

.gold-text-gradient {
  background: linear-gradient(90deg, #AD7819 0%, #DEA64A 20.77%, #FFEB95 35.13%, #EFBC68 48.97%, #FFD67A 60.77%, #BE8D3C 74.36%, #9E6C0C 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: ease-in-out;
  transition-duration: 1s;
}

.rotate-0 {
  transform: rotate(0);
  transition: ease-in-out;
  transition-duration: 1s;
}

.center-screen {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.gap-12 {
  gap: 12px;
}

.gap-32 {
  gap: 32px;
}

.line-height-0 {
  line-height: 0 !important;
}

.no-style {
  color: transparent !important;
  text-decoration: none !important;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.display-none {
  display: none;
}

.wrap-in-two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.wishlist-icon {
  position: absolute;
  left: 16px;
  top: 16px;
  z-index: 1000000;
}

.modal {
  z-index: 100000000 !important;
}

.country-code {
  position: absolute;
  left: 16px;
  top: 15px
}

#ct-container {
  z-index: 100000000 !important;
}

.backdrop-loading {
  // backdrop
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000000 !important;
}

.force-line-height-1{
  line-height: 1 !important;
}