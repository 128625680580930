@import '../../assets/mixins/font_color_mixins';
@import '../../assets/common/common_style';
@import '../../assets/mixins/component_style_mixins';

.login-component {

  max-width: 362px;
  position: relative;

  .login-form-action-section {
    .custom-btn-section {
      .custom-rich-btn {
        height: 22px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }

  @media screen and (max-width: $isTabletOrMobile-max) {

    .address-input {
      border: 1px solid $fabelle-primary-brown;
      z-index: 0;
      margin: 0 0 20px 0 !important;
      padding: 14.5px 10px !important;
      border-radius: 4px !important;
      height: 100% !important;
      font-family: 'Euphemia-UCAS-regular', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;

      select,
      select:focus,
      select:active,
      input,
      input:focus,
      input:active {
        z-index: 0 !important;
        border: none;
        outline: none;
        @include euphemia-400-16;
        width: 100%;
        max-width: 560px;
        color: $fabelle-primary-brown;
        background: transparent;
      }

    }
  }
  @media screen and (min-width: $isDesktopOrLaptop-min) {

    .address-input {
      border: 2px solid $fabelle-primary-brown;
      z-index: 0;

      select,
      select:focus,
      select:active,
      input,
      input:focus,
      input:active {
        z-index: 0 !important;
        border: none;
        outline: none;
        @include euphemia-400-16;
        width: 100%;
        max-width: 560px;
        color: $fabelle-primary-brown;
        background: transparent;
      }
    }
  }

  .address-input {
    position: relative;
    padding: 18px 10px;
    // border changed accor to size >
    border-radius: 12px;
    margin: 24px 0 16px 0;
    height: 36px;
    display: flex;
    align-items: center;

    //.address-input-wrap{
    //  background-color: $fabelle-primary-brown;
    //  padding: 18px 10px;
    //}

    .label-value {
      position: absolute;
      top: -12px;
      left: 24px;
      text-transform: capitalize;
      color: $fabelle-primary-brown;
      padding: 0 2px;
    }

    .hidden {
      display: none;
      animation: fade-out;
      animation-duration: 1s;
    }

    .show {
      animation: fade-in;
      animation-duration: .5s;
    }

    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes fade-out {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }

}
