@import '../../assets/mixins/font_color_mixins';
@import '../../assets/common/common_style';

.product-card-horizontal-component {
  .custom_model_action{
    margin: 0;
    height: 100%;
    .modal_comments_parent{
      position: relative;
      span{
        position: absolute;
        font-family: 'TradeGothic LT CondEighteen',sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        //line-height: 10%;
        display: flex;
        align-items: center;
        color: #343434;
        left: 36px;
        top: 0px;
        background-color: #fff;
        padding: 0 2px 2px 2px;
      }
    }
    textarea{
      font-family: 'TradeGothic LT CondEighteen',sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      color: #34231A;
      min-height: 140px;
      padding: 18px 15px;
      margin: 8px 24px 24px 24px;
      width: -webkit-fill-available;
      border: 1px solid #34231a !important;
      &:focus-visible{
        border: 1px solid #34231a !important;
        outline: none;
      }
    }
    .save{
      font-family: 'Euphemia UCAS',sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 15px;
      text-align: right;
      color: #BE924A;
    }
    .heading_model{
      font-family: 'Euphemia UCAS',sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      //line-height: 61%;
      color: #BE924A;
      opacity: 0.6;
    }
    .modal_price{
      padding: 0 24px 24px 24px;
    }
    .modal_middle_content{
      background-image: url("../../assets/images/order-detail-card-border.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border-radius: 0;
      margin: 29px 24px 48px 24px;
      .left-side{
        max-width: 100px;
        padding: 1px;
        display: flex;
        .featured-image{
          width: 100%;
          margin: auto;
        }
      }
      .right-side{
        padding: 16px;
      }





    }
  }
  .modal-content{
    min-height: 100vh;

  }
  .modal-body{
    padding: 0!important;
  }


  border-radius: 16px;
  overflow: hidden;
  background: transparent;
  padding: 2px;
  //padding: 2px;
  position: relative;
  //
  //&:after{
  //  content:"";
  //  position:absolute;
  //  top:0;
  //  left:0;
  //  right:0;
  //  bottom:0;
  //  border-radius:8px;
  //  padding:2px;
  //  background:linear-gradient(90deg, #AD7819 0%, #DEA64A 20.77%, #FFEB95 35.13%, #EFBC68 48.97%, #FFD67A 60.77%, #BE8D3C 74.36%, #9E6C0C 100%);
  //  -webkit-mask:
  //          linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);
  //  -webkit-mask-composite: xor;
  //  mask-composite: exclude;
  //}
  .custome-md-view-more {
    //@include trade-con-700-16;
    @include euphemia-700-16;
    color: #000000;

    span {
      img {
        width: 7px;
        height: 19px;
      }
    }
  }

  .image-section {
    min-width: 169px;
    min-height: 169px;
    display: flex;
    justify-content: center;
    align-items: center;
    //background-size: cover;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    //height: 100%;
  }

  .content-section {
    width: 100%;

    .border-box {
      border: 1px solid #34231A;
      gap: 10px;
    }
  }

  .card-content-wrap {
    //padding: 1px;
    background: transparent;
    border-radius: 16px;

    .featured-image {
      width: 170px;
      object-fit: contain;
      border-radius: 10px;
    }

    .golden-bg {
      background-color: #BE924A;
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px;

    }
  }
}