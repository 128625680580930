@import '../../assets/mixins/font_color_mixins';
@import '../../assets/common/common_style';
@import "../../assets/mixins/component_style_mixins";

.desktop-cart-list-component{
  .border-box {
    border: 1px solid $fabelle-primary-brown;
    border-radius: 4px;
    max-width: 111px;
    max-height: 32px;
  }
  .desktop-product-listing-wrap {

    .desktop-product-listing {
      //padding: 1px;
      //background: #34231A;
      //border-radius: 16px;
      padding: 0 40px;
    }

    .product-image {
      width: 100px;
      object-fit: contain;
      border-radius: 4px;
      //background: url("../../assets/images/single-product-pattern-bg.png") no-repeat center center;
      background-size: contain;
    }
  }
  .divder-line {
    height: .5px;
    margin: 0;
    width: 100%;
    background: linear-gradient(90deg, #AD781910 0%, #DEA64A40 20.77%, #FFEB95 35.13%, #EFBC68 48.97%, #FFD67A 60.77%, #BE8D3C40 74.36%, #9E6C0C10 100%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}
