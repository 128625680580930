@import '../../assets/mixins/font_color_mixins';
@import '../../assets/common/common_style';
@import "../../assets/mixins/component_style_mixins";

.product-card-component {
  position: relative;

  @media screen and (max-width: $isTabletOrMobile-max) {
    width: 164px;
    min-height: 313px;
    padding: 12px;
    background: transparent;
    //background-image: url("../../assets/images/product-card-mobile-bg.png");
    //background-size: contain;
    //background-repeat: no-repeat;
    .featured-image {
      width: 100%;
      object-fit: contain;
    }
    .wishlist-icon{
     position: absolute;
      right: 16px;
      top: 16px;
      z-index: 100;
    }

  }
  @media screen and (max-width: 1444px) and (min-width: 1024px) {
height: 100%;
    max-width: 216px;
    min-height: 490px;
    padding: 16px;
    //background-image: url("../../assets/images/product-card-mobile-bg.png");
    background-size: contain;
    background-repeat: no-repeat;
    .featured-image {
      width: 100%;
      height: 30vh;
      object-fit: contain;
    }
    .wishlist-icon{
     position: absolute;
      right: 16px;
      z-index: 100;
      top: 16px;
    }

  }
  @media screen and (min-width: 1445px) {
    height: 100%;
    max-width: 300px;
    min-width: 300px;
    min-height: 490px;
    padding: 16px;
    //background-image: url("../../assets/images/product-card-mobile-bg.png");
    background-size: contain;
    background-repeat: no-repeat;
    .featured-image {
      width: 100%;
      height: 30vh;
      //width: 200px;
      //height: 200px;
      object-fit: contain;
    }
  }
  background: transparent;
  border-radius: 16px;

  .border-box {
    border: 1px solid #34231A;
    box-sizing: border-box;
    border-radius: 4px;
    height: 40px;
  }

  .brown-button {
    @include fabelle-brown-button-fill;
    border-radius: 8px;
    margin-top: 10px;
  }

  .custom-btn-section {
    margin-top: 10px;

    .custom-rich-btn {
      padding-left: 35.5px;
      padding-right: 35.5px;

    }
  }

  @media screen and (max-width: 1023px) {
    .mb-view-more-bnt {
      .custom-rich-btn {
        padding: 6.5px 33px;
      }
    }
    .ratings-wrapper {
      justify-content: flex-start;

      img {
        width: 14px;
        height: 14px;
      }
    }
  }
  @media screen and (min-width: 1024px) {
    .ratings-wrapper {
      justify-content: center;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .button-ouline-dark {
    background-image: url("../../assets/images/button-dark-outline.png");
    background-repeat: no-repeat;
  }

  .last-text-center {
    text-align: center;
    color: #212529 !important;
  }

  .custom-button-wrapper {
    .custom-btn-section {
      width: 100%;
      button {
        width: 100%;
      }
    }
  }

  .plain-button,
  .plain-button:active,
  .plain-button:focus,
  .plain-button:hover {
    background: var(--Gold, linear-gradient(90deg, #AD7819 0%, #DEA64A 20.77%, #FFEB95 35.13%, #EFBC68 48.97%, #FFD67A 60.77%, #BE8D3C 74.36%, #9E6C0C 100%));
    padding:9.5px 24px 14.5px;
    width: 100%;
    outline: none;
    border: none;
    cursor: pointer;
    max-width: 200px;
  }

 
  

}
